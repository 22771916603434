#launchContainer {
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

#launchContainer > div {
	text-align: center;
}
