html {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: initial;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}

.message-wrapper {
  top: 15%;
  position: relative;
}

@media screen and (orientation:portrait) {
  .orientation-wrapper {
    display: block!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    text-align: center;
  }
}
@media screen and (orientation:landscape) { 
  .orientation-wrapper {
    display: none;
  }
 }